<template>
  <div >
  
    <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3 textTitulo">
          Bienvenido al proceso de identificación de {{agencyName}}
        </h1>
    </v-col>    
      <img alt="Identificacion Citelis" src="../assets/verificacion.png">
      
      <br>
      <v-col class="mb-4">
      <!--  <h5 class="display-2 font-weight-bold mb-3 textSubtitulo">
          Accede a tu proceso de identificación.
        </h5> -->
        <span class="display-2 mb-3 textSubtitulo">
          Accede a tu proceso de identificación.
        </span> 
      </v-col> 
      <v-col class="mb-4">
        <v-btn
        v-if="agencyParamUrl !== 'citelisPM' "
        class="btnServ"
        rounded
        x-large
        x-small
        :to="'/verificacionPF/'+agencyUrl"
      >Persona Física</v-btn>

      <v-btn
      v-if="agencyParamUrl == 'citelisPM' "
        class="btnServ"
        rounded
        x-large
        x-small
        :to="'/verificacionPM/'+agencyUrl"
      >Persona Moral</v-btn>

    </v-col> 
<!--     <v-col class="mb-4">
        <v-btn
        class="btnServ"
        :to="'/verificacionPM/'+agencyUrl"
        rounded
        x-large
        x-small
      >Persona Moral</v-btn>
    </v-col> -->

    <v-col class="textoIns">
      <span >
        Recomendamos usar una red WiFi estable.
      </span>
    </v-col>

    <v-col class="textoIns2">
      <span>
        Recomendamos conectarte en tu teléfono móvil a través de una red WiFi estable.
      </span>
    </v-col>

    <!--  <HelloWorld msg="Welcome to Your Vue.js App"/>  -->
    
      <v-col  offset="12">
      </v-col>
    
    <v-col  offset="12">
      </v-col>


    <v-col  offset="12">
    </v-col>

    <v-col  offset="12">
    </v-col>
    <v-col  offset="12">
    </v-col>
    <v-col  offset="12">
    </v-col>

</div>
</template>

<script>
// @ is an alias to /src
/* import HelloWorld from '@/components/HelloWorld.vue' */

export default {
  methods: {
  },
  data () {
    return {
      agencyName : '',
      agencyUrl:'',
      agencyParamUrl : '',
      agency : [
        {
            "Agency": "Ford citelis® Morelia",
            "Uneg": "FORD MORELIA PERIODISMO",
            "NameMP": "PFísica FORD Periodismo",
            "url":"FordMoreliaPeriodismo",
            "clientid":"629a495e79cc6d001bacb9a3",
            "flowId":"62b0a44215dabd001db9c3f6",
            "files":"/RMO.pdf"
        },
        {
            "Agency": "Ford citelis® Morelia",
            "Uneg": "FORD MORELIA AMÉRICAS",
            "NameMP": "PFísica FORD Camelinas",
            "url":"FordMoreliaAmericas",
            "clientid":"629a495e79cc6d001bacb9a3",
            "flowId":"62b0a6f88b9028001c193aee",
            "files":"/RMO.pdf"
        },
        {
            "Agency": "Ford citelis® Querétaro",
            "Uneg": "FORD QUERÉTARO",
            "NameMP": "PFísica FORD Qro",
            "url":"FordQueretaro",
            "clientid":"629a495e79cc6d001bacb9a3",
            "flowId":"62b0a997651e45001dc2ce79",
            "files":"/RMO.pdf"
        },
        {
            "Agency": "Ford citelis® Celaya",
            "Uneg": "FORD CELAYA",
            "NameMP": "PFísica FORD Celaya",
            "url":"FordCelaya",
            "clientid":"629a495e79cc6d001bacb9a3",
            "flowId":"62b0a986651e45001dc2ce3f",
            "files":"/RMO.pdf"
        },
        {
            "Agency": "Mazda citelis® Morelia",
            "Uneg": "MAZDA MORELIA",
            "NameMP": "PFísica MAZDA MLM",
            "url":"MazdaMorelia",
            "clientid":"629a495e79cc6d001bacb9a3",
            "flowId":"62b0e880651e45001dc39f80",
            "files":"/RAJ.pdf"
        },
        {
            "Agency": "Mazda citelis® Uruapan",
            "Uneg": "MAZDA URUAPAN",
            "NameMP": "PFísica MAZDA MLM",
            "url":"MazdaUruapan",
            "clientid":"629a495e79cc6d001bacb9a3",
            "flowId":"62b0ee5c8b9028001c1a28bc",
            "files":"/RAJ.pdf"
        },
        {
            "Agency": "Mazda citelis® Lázaro Cárdenas",
            "Uneg": "MAZDA LÁZARO CÁRDENAS",
            "NameMP": "PFísica MAZDA LC",
            "url":"MazdaLC",
            "clientid":"629a495e79cc6d001bacb9a3",
            "flowId":"62b0ee70962440001e33f71f",
            "files":"/RAJ.pdf"
        },
        {
            "Agency": "Mazda citelis® San Luis Potosí",
            "Uneg": "MAZDA SAN LUIS",
            "NameMP": "PFísica MAZDA San Luis",
            "url":"MazdaSLP",
            "clientid":"629a495e79cc6d001bacb9a3",
            "flowId":"62b0ee83962440001e33f74c",
            "files":"/RAP.pdf"
        },
        {
            "Agency": "Mazda citelis® San Luis Potosí",
            "Uneg": "MAZDA SAN LUIS CARRETERA 57",
            "NameMP": "PFísica MAZDA Carretera 57",
            "url":"MazdaSLPCarretera",
            "clientid":"629a495e79cc6d001bacb9a3",
            "flowId":"632b552860fe52001d85a54b",
            "files":"/RAP.pdf"
        },
        {
            "Agency": "Mazda citelis® San Luis Potosí",
            "Uneg": "MAZDA SAN LUIS CARRANZA",
            "NameMP": "PFísica MAZDA Carranza",
            "url":"MazdaSLPCarranza",
            "clientid":"629a495e79cc6d001bacb9a3",
            "flowId":"6329fb9a6d082d001c9b11ca",
            "files":"/RAP.pdf"
        },
        {
            "Agency": "Mazda citelis® Coacalco",
            "Uneg": "MAZDA COACALCO",
            "NameMP": "PFísica MAZDA MLM",
            "url":"mazdaCoacalco",
            "clientid":"629a495e79cc6d001bacb9a3",
            "flowId":"62ab4d12651e45001db67139",
            "files":"/RKO.pdf"
        },
        {
            "Agency": "Mazda citelis® Ecatepec",
            "Uneg": "MAZDA ECATEPEC",
            "NameMP": "PFísica MAZDA Ecatepec",
            "url":"MazdaEcatepec",
            "clientid":"629a495e79cc6d001bacb9a3",
            "flowId":"62b09f6f651e45001dc2aa68",
            "files":"/RKO.pdf"
        },
        {
            "Agency": "JAC citelis® Morelia",
            "Uneg": "JAC MORELIA",
            "NameMP": "PFísica JAC MLM",
            "url":"JacMorelia",
            "clientid":"629a495e79cc6d001bacb9a3",
            "flowId":"62b5d489e7cb81001ca87f53",
            "files":"/RAO.pdf"
        },
        {
            "Agency": "JAC citelis® León",
            "Uneg": "JAC LEÓN",
            "NameMP": "PFísica JAC León",
            "url":"JacLeon",
            "clientid":"629a495e79cc6d001bacb9a3",
            "flowId":"62e2d92188b346001c90ae8b",
            "files":"/RAO.pdf"
        },
        {
            "Agency": "JAC citelis® San Luis Potosí",
            "Uneg": "JAC SLP",
            "NameMP": "PFísica JAC SLP",
            "url":"JacSLP",
            "clientid":"629a495e79cc6d001bacb9a3",
            "flowId":"62e31268e70f8a001c1f78f6",
            "files":"/RAO.pdf"
        },
        {
            "Agency": "HINO citelis® Morelia",
            "Uneg": "HINO MORELIA",
            "NameMP": "PFísica HINO MLM",
            "url":"HinoMorelia",
            "clientid":"629a495e79cc6d001bacb9a3",
            "flowId":"62f522717b0f3a001cda482e",
            "files":"/RCJ.pdf"
        },
        {
            "Agency": "HINO citelis® CDMX",
            "Uneg": "HINO CDMX",
            "NameMP": "PFísica HINO CDMX",
            "url":"HinoCDMX",
            "clientid":"629a495e79cc6d001bacb9a3",
            "flowId":"62f520aef27da6001cb20d2d",
            "files":"/RCJ.pdf"
        },
        {
            "Agency": "Mercedes Benz Ravisa Haus Morelia",
            "Uneg": "RAVISA HAUS MORELIA",
            "NameMP": "PFísica Ravisa Haus MLM",
            "url":"MercedesBenzMorelia",
            "clientid":"629a495e79cc6d001bacb9a3",
            "flowId":"629a495e79cc6d001bacb9a2",
            "files":"/RHA.pdf"
        },
        {
            "Agency": "Chirey Zamora",
            "Uneg": "CHIREY ZAMORA",
            "NameMP": "PFísica Chirey Zamora",
            "url":"ChireyZamora",
            "clientid":"629a495e79cc6d001bacb9a3",
            "flowId":"62ab46741982ef001dc287ae",
            "files":"/ARA.pdf"
        },
        {
            "Agency": "Chirey Morelia",
            "Uneg": "CHIREY MORELIA",
            "NameMP": "PFísica Chirey Morelia",
            "url":"ChireyMorelia",
            "clientid":"629a495e79cc6d001bacb9a3",
            "flowId":"631b7014e0bf79001defe107",
            "files":"/ARA.pdf"
        },
        {
            "Agency": "Chirey Uruapan",
            "Uneg": "CHIREY URUAPAN",
            "NameMP": "Pfísica Chirey UPN",
            "url":"ChireyUruapan",
            "clientid":"629a495e79cc6d001bacb9a3",
            "flowId":"645932489578e4001c16b2d8",
            "files":"/ARA.pdf"
        },
        {
            "Agency": "Jetour Citelis Morelia",
            "Uneg": "JETOUR MORELIA",
            "NameMP": "Pfísica Jetour MLM",
            "url":"JetourMorelia",
            "clientid":"629a495e79cc6d001bacb9a3",
            "flowId":"64f0bd1677b03f001dbc927d",
            "files":"/RCN.pdf"
        },
        {
            "Agency": "Jetour Citelis Querétaro",
            "Uneg": "JETOUR QUERÉTARO",
            "NameMP": "Pfísica Jetour QRO",
            "url":"JetourQueretaro",
            "clientid":"629a495e79cc6d001bacb9a3",
            "flowId":"64f0c159d2049f001c646f78",
            "files":"/RCN.pdf"
        },
        {
            "Agency": "Omoda Morelia",
            "Uneg": "OMODA MORELIA",
            "NameMP": "Pfísica OMODA MLM",
            "url":"OmodaMorelia",
            "clientid":"629a495e79cc6d001bacb9a3",
            "flowId":"64f21e6e9c457b001ca76ca3",
            "files":"/RSL.pdf"
        },
        {
            "Agency": "Omoda San Luis",
            "Uneg": "OMODA SAN LUIS",
            "NameMP": "Pfísica OMODA SLP",
            "url":"OmodaSanLuis",
            "clientid":"629a495e79cc6d001bacb9a3",
            "flowId":"64f22fda196812001cdf7cf8",
            "files":"/RSL.pdf"
        },
        {
            "Agency": "Sigo",
            "Uneg": "SIGO EN MOVIMIENTO",
            "NameMP": "Pfísica SIGO",
            "url":"SigoEnMovimiento",
            "clientid":"629a495e79cc6d001bacb9a3",
            "flowId":"64fb78eb350039001d9240d4",
            "files":"/SIGO.pdf"
        },
        {
            "Agency": "Jac Citelis Insurgentes Sur",
            "Uneg": "JAC CDMX",
            "NameMP": "Pfísica JAC CDMX",
            "url":"JacMexico",
            "clientid":"629a495e79cc6d001bacb9a3",
            "flowId":"6503a4a9374e08001bcea864",
            "files":"/RAO.pdf"
        },
        {
            "Agency": "CITELIS PERSONA FISICA",
            "Uneg": "CITELIS PERSONA FISICA",
            "NameMP": "citelisPF",
            "url":"citelisPF",
            "clientid":"629a495e79cc6d001bacb9a3",
            "flowId":"6568bc6abf12ee001c4d22b7",
            "files":"/"
        },
        {
            "Agency": "CITELIS PERSONA MORAL",
            "Uneg": "CITELIS PERSONA MORAL",
            "NameMP": "citelisPM",
            "url":"citelisPM",
            "clientid":"629a495e79cc6d001bacb9a3",
            "flowId":"6568e11e9d3258001c924f20",
            "files":"/"
        },
        


        /* {
            "Agency": "Mercedes Benz Ravisa Haus Querétaro",
            "Uneg": "RAVISA HAUS BERNARDO QUINTANA",
            "NameMP": "PFísica Ravisa Haus QRO",
            "url":"MercedesBenzQRO",
            "clientid":"629a495e79cc6d001bacb9a3",
            "flowId":"62ab46741982ef001dc287ae",
            "files":"/RHA.pdf"
        },
        {
            "Agency": "Mercedes Benz Ravisa Haus Querétaro",
            "Uneg": "RAVISA HAUS CONSTITUYENTES",
            "NameMP": "PFísica Ravisa Haus Const",
            "url":"MercedesBenzConst",
            "clientid":"629a495e79cc6d001bacb9a3",
            "flowId":"631b7014e0bf79001defe107",
            "files":"/RHA.pdf"
        }, */
        
        ],


    }
  },
  created () {
    /* console.log("Obteniendo name de agencia: ") */
    this.agencyParamUrl = this.$route.params.id

    const object = this.agency.find(c => c.url.toLocaleLowerCase() === this.agencyParamUrl.toLocaleLowerCase())
    console.log(object)
    
    if (object === undefined) {
      this.$router.push('404')
    }else{
      console.log("ag:" + object.Agency)
    }
    this.agencyName = object != null ? object.Agency : ''
    this.agencyUrl = object != null ? object.url : ''

  },
  name: 'HomeView',
  components: {
    /* HelloWorld */
  }
}
</script>

<style scoped>

  /*  ESTILOS DE BOTONES  */
  .btnServ{
    background: #00225C 0% 0% !important; 
    color:white !important;
    text-transform: initial;
    font-family: "Metropolis_Bolt";
    font-size: 18px;
    width: 226px;
    height: 48px;
    /*box-shadow: 0px 3px 6px #00000029;
    border-radius: 6px;
    opacity: 1;
    color:white !important;
    padding: 0px !important;
    width: 140px;
    height: 33px;
    transition: 0.6s; */
  }
  
  .btnServ:hover{
    background: #00225C 0% 0% !important; 
    color:white !important;
  /*  border-radius: 2px !important;
    opacity: 1 !important; 
    color:white !important;
    padding: 0px !important; */
  }

  .textTitulo{
    font-family: "Metropolis_semiBolt";
    font-size: 22px;
    margin-top: 50px;
  }

  .textSubtitulo{
    font-family: "Metropolis_regular";
    font-size: 14px;
    color: #000000;
  }
  .textSubtituloGray{
    font-family: "Metropolis_regular";
    font-size: 14px;
    color: #3F3F3F;
  }

  .recomendaciones{
    font-family: "Metropolis_regular";
    font-size: 14px;
    color: #3F3F3F;
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .textoIns{
    font-family: "Metropolis_regular";
    font-size: 14px;
    color: #3F3F3F;
    margin-top: 75px;
    margin-bottom: 75px;
    display: none;
  }
  .textoIns2{
    font-family: "Metropolis_regular";
    font-size: 14px;
    color: #3F3F3F;
    margin-top: 75px;
    margin-bottom: 75px;
    margin-left: 10px;
    margin-right: 10px;
    display: block;
  }

  /* movil */
@media only screen and (max-width: 768px) {
  .textoIns{
    font-family: "Metropolis_regular";
    font-size: 14px;
    color: #3F3F3F;
    margin-top: 75px;
    margin-bottom: 75px;
    display: block;
  }

  .textoIns2{
    font-family: "Metropolis_regular";
    font-size: 14px;
    color: #3F3F3F;
    margin-top: 75px;
    margin-bottom: 75px;
    display: none;
  }
}



</style>
