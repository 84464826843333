import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue' 
import MainCitelis from '../views/MainCitelis.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomeViewCit',
    component: MainCitelis
  },
  {
    path: '/404/',
    name: 'not-found',
    component: () => import(/* webpackChunkName: "about" */ '../views/ErrorPage.vue')
  },
  {
    path: '/:id',
    name: 'HomeView',
    component: HomeView
  }, 
  {
    path: '/verificacionPF/:id',
    name: 'p-fisica',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PersonasFisicas.vue')
    /* component: HomeView */
  },
  {
    path: '/verificacionPM/:id',
    name: 'p-moral',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PersonasMorales.vue')
    /* component: HomeView */
  }
  /* {
    path: '/:pathMatch(.*)*',
    component: Page404
  }, */
]

const router = new VueRouter({
  routes
})

export default router
