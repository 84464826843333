<template>
  <div id="app">
    <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav> -->

    <v-app-bar
      class="citelisColor"
      elevation="2"
    >
    <!-- logo movil -->
    <img
        class="citeLogo"
        alt="Citelis"
        src="/citelis-logo-blanco.png"
      >
  
    </v-app-bar>

    <!-- >seccion general vue router  -->
    <router-view/>

    <!-- footer -->
    <v-footer
    bottom relative padless
    max-height="100px"
  >
    <v-card
      class="flex footerColor"
      flat
      tile
    >
      <v-card-title class="teal" >
        <img
          class="citeLogo"
          alt="Citelis"
          src="/citelis-logo-color.png"
          
        > 
        <v-spacer></v-spacer>
      </v-card-title>

      <v-card-text class="text-center bottomFooterColor" max-height="10px">
      <p class="textFooter">Derechos reservados © CITELIS {{ new Date().getFullYear() }} </p>
      </v-card-text>
    </v-card>
    </v-footer>
   <!--  <my-component first="componentee" last="'test stencil' JS"></my-component>   -->
    <!-- <citelis-footer position="relative"></citelis-footer> -->
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

html {
    overflow-x: hidden;
    width: 100%;
}

 body {
    overflow-x: hidden;
    overflow-y: hidden;
    width: 100%
}

@font-face {
  font-family: "Metropolis_Bolt";
  src: local("Metropolis_Bolt"),
  url(./assets/fonts/MetropolisFont/Metropolis-Bold.otf) format("truetype");
}

@font-face {
  font-family: "Metropolis_semiBolt";
  src: local("Metropolis_semiBolt"),
  url(./assets/fonts/MetropolisFont/Metropolis-SemiBold.otf) format("truetype");
}

@font-face {
  font-family: "Metropolis_regular";
  src: local("Metropolis_regular"),
  url(./assets/fonts/MetropolisFont/Metropolis-Regular.otf) format("truetype");
}
/* nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
} */


//barra de navegación
.citelisColor{
  background-color: #00225C !important;
}

//FOOTER

.footerColor{
  background-color: #E8E8E8 !important;
}

.bottomFooterColor {
  background-color:  #F5F5F5 !important;
}

.textSubtitulo{
    font-family: "Metropolis_regular";
    font-size: 14px;
    color: #000000;
  }

  .textFooter{
    font-family: "Metropolis_regular";
    font-size: 10px;
    color: #3F3F3F;
    margin-bottom: auto;
    margin-top: 10px;
  }

  /* logo para dispositivos mayores a 768px */
  .citeLogo{
    margin-left:139px ;
  }
  .textAvisoPriv{
    font-family: "Metropolis_regular";
    font-size: 14px;
    color: #000000;
    margin-right: 151px;
  }


  /* logo para dispositivos moviles */
@media only screen and (max-width: 768px) {
  .citeLogo{
    margin-left:39px ;
  }

  .textAvisoPriv{
    font-family: "Metropolis_regular";
    font-size: 14px;
    color: #000000;
    margin-right: 40px;
  }
}

</style>
